import { graphql } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import Masonry from "react-masonry-css";
import ContactSection from "../components/contact/ContactSection/ContactSection";
import HeroSection from "../components/HeroSection/HeroSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cleanImgBase, Images } from "../utils/findImage";

interface KitchenCabinetsPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
    projectImages: Images;
  };
}

const KitchenCabinetsPage: React.FC<KitchenCabinetsPageProps> = ({
  location,
  data,
}) => {
  return (
    <Layout>
      <SEO
        title="Premium Kitchen Cabinets Refinishing &amp; Painting, Charlotte, NC"
        description="Get a beautiful new look for your kitchen with high-quality, durable cabinet coatings. Contact us for a free estimate."
        slug={location.pathname}
      />

      <HeroSection
        bgImage={data.bgImage.gatsbyImageData}
        heading="Kitchen cabinets"
      >
        We bring your kitchen back to life with the highest quality of durable
        factory coatings.
      </HeroSection>

      <section>
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-16 lg:items-center">
            <div className="w-full">
              <div className="relative">
                <StaticImage
                  src={"../images/projects/kitchen-cabinets-painting-3.jpg"}
                  alt="kitchen cabinets painting"
                  objectFit="cover"
                  width={600}
                  height={600}
                  quality={90}
                  className="z-20"
                />

                <div className="absolute top-8 -left-8 max-h-full max-w-full w-[584px] h-[584px] z-0 overflow-hidden">
                  <div className="bg-[#f8f8fa] w-full h-full" />
                </div>
              </div>
            </div>

            <div className="relative z-50">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-black mb-6">
                Have your dream kitchen with our premium cabinet finish
              </h2>
              <p className="mb-4">
                We offer a variety of options ranging from eco-friendly products
                to solvent-based lacquers. We work closely with our partnered
                carpenters to bring your new builds, modifications, or refacing
                services to life. Our kitchen refinish process consists of
                on-site and in-shop spraying to minimize turnaround time and get
                you back into your new space as quickly as possible.
              </p>
              <p className="mb-4">
                You will be surprised at what a difference simply refinishing or
                refacing your existing cabinets can make!
              </p>
              <p>
                Contact us for a free estimate, and let us transform your
                kitchen into something beautiful.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-0">
        <div>
          <Masonry
            breakpointCols={{ default: 4, 640: 1, 1024: 2 }}
            className="col-span-full flex flex-wrap"
            columnClassName="px-0.5 bg-clip-padding"
          >
            {data.projectImages.nodes.map((node, index) => (
              <div key={`projectImage-${index}`} className="mb-1">
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={cleanImgBase(node.base)}
                />
              </div>
            ))}
          </Masonry>
        </div>
      </section>

      <ContactSection>
        Contact us for a free estimate, and let us make you proud of your
        kitchen.
      </ContactSection>
    </Layout>
  );
};

export default KitchenCabinetsPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(
      original: { src: { regex: "/kitchen-cabinets-painting-4/" } }
    ) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }

    projectImages: allFile(
      filter: {
        base: {
          in: [
            "kitchen-cabinets-painting-1.jpg"
            "kitchen-cabinets-painting-2.jpg"
            "kitchen-cabinets-painting-4.jpg"
            "kitchen-cabinets-painting-7.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90, aspectRatio: 0.75)
          original {
            src
          }
        }
        base
      }
    }
  }
`;
